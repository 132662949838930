<template>
  <div>
    <h2>{{ $t("pages.UserGroupEdit") }}</h2>
    <user-group-form :user-group="userGroup" payload-process="edit"/>
    <b-loading :is-full-page="true" :active="loading"  />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import userGroupForm from "../components/baseGroups/UserGroupForm";
export default {
  name: "UserGroupEdit",
  components: {userGroupForm},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('access_groups', ['userGroup'])
  },
  methods: {
    loadUserGroupDetail() {
      this.loading = true;
      this.$store.dispatch('access_groups/getUserGroupDetail', this.$route.params.id).then(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.$store.dispatch('access_groups/clearObjectDetail');
    this.loadUserGroupDetail();
  }
}
</script>

<style scoped>
h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}

</style>